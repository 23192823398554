import cookie from 'js-cookie';
import moment from 'moment';


// set in cookie
export const setCookie = (key, value) => {
	// eslint-disable-next-line
	if (window != 'undefined') {
		cookie.set(key, value, {
			expires: 1
		});
	}
}

// remove from cookie
export const removeCookie = (key) => {
	// eslint-disable-next-line
	if (window != 'undefined') {
		cookie.remove(key, {
			expires: 1
		});
	}
}

// get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
	// eslint-disable-next-line
	if (window != 'undefined') {
		return cookie.get(key);
	}
}

// set in localstorage
export const setLocalStorage = (key, value) => {
	// eslint-disable-next-line
	if (window != 'undefined') {
		localStorage.setItem(key, JSON.stringify(value));
	}
}

// remove from localstorage
export const removeLocalStorage = (key) => {
	// eslint-disable-next-line
	if (window != 'undefined') {
		localStorage.removeItem(key);
	}
}

// get from localstorage
export const getFromLocalStorage = (key) => {
	// eslint-disable-next-line
	if (window != 'undefined') {
		const value = localStorage.getItem(key)
		return value ? JSON.parse(value) : ''
	}
}

// authenticate user by passing data to cookie and localstorage durinisAuthg login
export const authenticate = (response, next) => {
	const token = response.data
	setCookie('token', token);
	next();
}

export const addUserToken = (token, next) => {
	let usersTokens = JSON.parse(cookie.get('usersTokens'));
	if (usersTokens.indexOf(token) === -1) {
		setCookie('token', token);
		usersTokens.push(token);
		setCookie('usersTokens', JSON.stringify(usersTokens));
	}
	next();
}

export const getUsersTokens = () => {
	return JSON.parse(cookie.get('usersTokens'));
}

export const getTokenProperty = (property) => {
	const token = getToken();
	const data = tokenData(token)
	if (data) {
		return data[property]
	}
	return null;
}

export const setActiveToken = (token, next) => {
	setCookie('token', token);
	next();
}

export const getTokenPropertyByToken = (token, property) => {
	return tokenData(token)[property];
}

export const getToken = () => {
	if (window !== 'undefined') {
		return getCookie('token');
	}
}

export const logged = () => {
	const token = getToken();

	if (!token || !isValidToken(token)) {
		return false;
	}
	return true;
}

export const tokenData = (token) => {
	if (!token) {
		return null;
	}
	return JSON.parse(atob(token.split('.')[1]));
}

export const isValidToken = (token) => {
	return moment().isBefore(moment(new Date(tokenData(token).exp * 1000)));
}

export const hasPermission = (permissionExpected) => {
	let ret = false;
	const token = getToken();

	if (token === null) {
		return false;
	}

	const permissions = tokenData(token).permissions;
	if (!permissions || permissions.length === 0) {
		return false
	}

	permissions.forEach(p => {
		if (p === permissionExpected) {
			ret = true;
			return;
		}
	});

	return ret;
}

export const signout = next => {
	removeCookie('token');

	if (next) {
		next();
	}
};